<template>
  <div>
    <VNavigationDrawer
      :value="leftDrawer"
      :class="mapLeftDrawerObject"
      touchless
      clipped
      fixed
      disable-route-watcher
      stateless
      open-on-click
      mobile-break-point="1000000"
      width="320"
    >
      <LeftDrawerInMap />
    </VNavigationDrawer>

    <VNavigationDrawer
      :value="rightDrawer"
      :class="mapRightDrawerObject"
      touchless
      clipped
      fixed
      stateless
      disable-resize-watcher
      disable-route-watcher
      mobile-break-point="1000000"
      right
      width="400"
    >
      <RightDrawerInMap />
    </VNavigationDrawer>

    <GmapMap
      ref="map"
      v-show="loadCompleted"
      :center="initialPosition"
      :options="mapOptions"
      :style="vueMapContainerStyle"
      :zoom="initialPosition.zoomLevel"
      @idle="drawMarkerWithinDrawingRange"
      @center_changed="onCenterChanged"
      @zoom_changed="onZoomChanged"
      class="map"
    >
      <GmapMarker
        ref="markers"
        v-for="(marker, index) in permittedRecorders"
        :key="index"
        :position="marker.position"
        :clickable="true"
        :draggable="isDraggable(marker)"
        :icon.sync="marker.icon"
        @click="showDrawers(marker)"
        @dragend="showCoordinatesChangeModal($event, marker)"
        @dragstart="startTownRecorderIconDrag($event, marker)"
      />
      <GmapInfoWindow
        :options="infoOptions"
        :position="infoWindowPos"
        :opened="infoWinOpen"
        @closeclick="infoWinClose"
      >
        {{ infoContent }}
      </GmapInfoWindow>
    </GmapMap>
    <LoginPositionButton
      :map-state="mapState"
      class="hidden-xs-only"
    />

    <ChangeCoordinationDialog
      v-if="changeTownRecorderCoordDialog"
      :recorder-name="selectedMarker.name"
      @change-coordination="setNewTownRecorderCoordinates"
      @cancel="cancelTownRecorderCoordinateChange"
    />

    <VSnackbar
      v-model="snackbar"
      :color="snackBarColor"
      :bottom="true"
      :timeout="2000"
    >
      タウンレコーダーの座標を更新しました
      <VBtn
        @click="snackbar = false"
        color="white"
        flat
      >
        OK
      </VBtn>
    </VSnackbar>
    <VSnackbar
      v-model="snackbarOverlatLng"
      :color="snackBarErrorColor"
      :bottom="true"
      :timeout="2000"
    >
      その位置には座標を設定できません。
      <VBtn
        @click="snackbarOverlatLng = false"
        color="white"
        flat
      >
        OK
      </VBtn>
    </VSnackbar>
    <template v-if="existsInvalidStateRecorders">
      <VBadge
        class="ma-3"
        overlap
        color="red"
      >
        <template slot="badge">
          <span>!</span>
        </template>
        <VBtn
          @click="openLeftDrawer()"
          class="ma-0"
          fab
          small
        >
          <v-icon>menu</v-icon>
        </VBtn>
      </VBadge>
    </template>
    <template v-else>
      <VBtn
        @click="openLeftDrawer()"
        class="ma-3"
        fab
        small
      >
        <VIcon>menu</VIcon>
      </VBtn>
    </template>
  </div>
</template>

<script>
  import { interval } from 'rxjs';
  import leftDrawerMixin from '@/components/mixins/leftDrawer';
  import { persistence } from '@/plugins/persistence';
  import ChangeCoordinationDialog from '~/components/maps/changeCoordinationDialog.vue';
  import LoginPositionButton from '~/components/maps/LoginPositionButton.vue';
  import RightDrawerInMap from '~/components/rightDrawerInMap.vue';
  import LeftDrawerInMap from '~/components/leftDrawerInMap.vue';
  import * as types from "~/store/types";

  export default {
    components: {
      ChangeCoordinationDialog,
      LoginPositionButton,
      RightDrawerInMap,
      LeftDrawerInMap,
    },
    mixins: [ leftDrawerMixin ],
    data() {
      return {
        mapOptions: {
          streetViewControl: false, // ストリートビューコントロールを非表示にする
          fullscreenControl: false, // フルスクリーンコントロールを非表示にする(フルスクリーンにできてしまうと配信画面のモーダルなどが背後に隠れてしまうため)
          mapTypeControl: true, // 地図・航空写真の切り替えを有効にする
          gestureHandling: 'greedy', // モバイルで見た際に一本指で移動ができるようにする
          mapTypeControlOptions: {
            position: 6, // LEFT_BOTTOM
          },
        },
        mapState: {
          lat: 0,
          lng: 0,
          zoomLevel: 0,
        },
        selectedMarker: null,
        streamingDialog: false,
        changeTownRecorderCoordDialog: false,
        date: new Date(),
        recorderIconDragEvent: null,
        dragStartLat: null,
        dragStartLng: null,
        snackbar: false,
        snackbarOverlatLng: false,
        snackBarColor: 'success',
        snackBarErrorColor: 'error',
        infoContent: '',
        infoWindowPos: null,
        infoWinOpen: false,
        currentMidx: null,
        dragging: false,
        initialPosition: {
          lat: 0,
          lng: 0,
          zoomLevel: 0,
        },
        infoOptions: {
          pixelOffset: {
            width: 0,
            height: -35
          },
        },
        latitudeLimit: 85,      // 緯度限界±85
        permittedRecorders: {},
        loadCompleted: false,
        unsubscribe: null,
        beforeIndex: null,
        pollingSubscription: null,
        notSelectedIcon: {
          url: "https://storage.googleapis.com/toa-townrec-release-001.appspot.com/video-camera-icon-gray.png",
          size: {width: 46, height: 46, f: "px", b: "px"},
          scaledSize: {width: 46, height: 46, f: "px", b: "px"},
        },
        selectedIcon: {
          url: "https://storage.googleapis.com/toa-townrec-release-001.appspot.com/video-camera-icon.png",
          size: {width: 46, height: 46, f: "px", b: "px"},
          scaledSize: {width: 46, height: 46, f: "px", b: "px"},
        },
      };
    },
    computed: {
      // scssで高さを設定しようとするとiPhoneで高さの計算がおかしくなったのでjs側で計算している
      vueMapContainerStyle() {
        const htmlHeight = document.querySelector('html').clientHeight;
        const headerHeight = 60/*px*/; //TODO: どこかに定数として切り出さないと後々面倒になりそう
        const mapHeight = htmlHeight - headerHeight + 'px';
        return {
          'width':  '100%',
          'height': mapHeight,
          'position': 'absolute',
          'left': 0,
          'top': 0,
        }
      },
      show() {
        return this.$store.state.view.type === 'recorder';
      },
      rightDrawer() {
        return this.$store.getters.rightDrawerInMap;
      },
      leftDrawer() {
        return this.$store.getters.leftDrawerInMap;
      },
      recorders() {
        const organizations = this.$store.state.recorders.organizations;
        return _.chain(this.$store.getters['map/permittedRecorders'])
          .groupBy(i => organizations[i.organization_id])
          .map((v, k) => {
            var orgName = '組織なし';
            if (k !== 'undefined') {
              orgName = k;
            }

            return {
              name: orgName,
              id: v[0].organization_id,
              children:
                _.map(v, i => {
                  return {
                    aux: i.aux,
                    device_id: i.device_id,
                    device_status: i.device_status,
                    id: i.id,
                    lat: Number(i.latitude),
                    lng: Number(i.longitude),
                    name: i.name,
                    organization_id: i.organization_id,
                    phone_no: i.phone_no,
                    plan: i.plan,
                    sim_traffic_limit: i.sim_traffic_limit,
                    type: i.type,
                    vpn: i.vpn,
                    youtube: i.youtube,
                  };
                })
            };
          })
          .value();
      },
      recorderAndIdMapping() {
        const recorders = {};
        this.recorders.forEach((organizations) => {
          const children = organizations.children;
          children.forEach((child) => {
            recorders[child.id] = (child);
          });
        });
        return recorders;
      },
      mapRightDrawerObject() {
        return {
          'map-right-drawer': this.$vuetify.breakpoint.mdAndUp,
          'map-right-drawer-mobile': !this.$vuetify.breakpoint.mdAndUp,
        }
      },
      mapLeftDrawerObject() {
        return {
          'map-left-drawer': this.$vuetify.breakpoint.mdAndUp,
          'map-left-drawer-mobile': !this.$vuetify.breakpoint.mdAndUp,
        }
      },
      allRecorderIdList() {
        return this.$store.getters['map/permittedRecorders'].map((recorder) => recorder.id);
      },
      selectedRecorder() {
        return this.$store.getters['map/selectedRecorder'];
      },
    },
    watch: {
      /**
       * Q: なぜ$routeのwetchをしてmountedやbeforeDestroyに書くべき処理をここに書いているか？
       * A: 元々これらの処理はmountedやbeforeDestroyフックに書かれていたのだが、
       * Google Maps APIにGoogle Mapインスタンスを破棄してもガベージコレクタに回収されずメモリリークするというバグがあり、
       * このせいでMAP画面に遷移するたびに新しくGoogle Mapインスタンスが作成されメモリを圧迫するようになってしまっていた。
       * そこで、MAP画面は常に表示された状態で、/maps以外のルートにいるときはv-showで非表示にするという手法を取った。
       * その結果として、本来動くはずのmounted・beforeDestoryフックは事実上使用不可能になってしまった。
       * そのため、$routeの変更を検知して手動でそれに該当する処理を書くこととなった。
       *
       * 参考URL: https://stackoverflow.com/questions/10485582/what-is-the-proper-way-to-destroy-a-map-instance
       */
      async $route(to, from) {
        if (to.path === '/maps' && from.path !== '/maps') {
          await this.initialize();
          return;
        }
        if (from.path === '/maps') {
          await this.destructor(to);
        }
      },
    },
    mounted() {
      this.initialize();
    },
    async beforeDestroy() {
      await this.handleBeforeDestroy();
    },
    methods: {
      async handleBeforeDestroy() {
        await new Promise(async (resolve) => {
          console.log("beforeDestroy");
          await this.$store.dispatch('map/clearRecorderIdAndLockStateMap');
          document.querySelector('body').style.overflowY = 'scroll';


          // polling を止める
          if (this.pollingSubscription) {
            console.log("will unsubscribe.", this.pollingSubscription);
            clearInterval(this.pollingSubscription);
            this.pollingSubscription = null;
            delete this.pollingSubscription;
          }

          const selectedRecorder = this.$store.getters['map/selectedRecorder'];
          const selectedRecorderIndex = this.permittedRecorders.findIndex((recorder) => {
            return recorder.id === selectedRecorder.id;
          });
          if (selectedRecorderIndex !== -1) {
            this.$refs.markers[selectedRecorderIndex].$markerObject.setIcon(this.notSelectedIcon);
          }

          this.$store.commit('map/selectRecorder', {});

          if (this.unsubscribe) {
            this.unsubscribe();
          }

          this.closeDrawers();
          resolve();
        });
      },
      async initialize() {
        if (this.$route.path !== '/maps') {
          return;
        }

        const isLocal = !process.env.useAuth;
        if (isLocal && this.$route.path !== '/maps') {
          return;
        }

        await this.$store.dispatch("setLoadingState", true);

        this.initializeForMobileDevices();
        this.pollingSubscription = setInterval(async () => {
          await this.fetchPermittedRecorders().catch((err) => {
            console.error(err);
            this.$store.dispatch("setLoadingState", false);
          });
          const selectedRecorderWasDeleted = !this.allRecorderIdList.includes(this.$store.getters['map/selectedRecorder'].id);

          if (selectedRecorderWasDeleted) {
            this.infoWinOpen = false;
            this.$store.dispatch('closeRightDrawerInMap');
          }

          // 以下処理が無いとポーリングでレコーダー一覧を更新した際に、選択してたレコーダーのアイコンが灰色になってしまう
          let selectedRecorder;

          if (this.isIE()) {
            selectedRecorder = persistence.restoreState('map/selectedRecorder');
            persistence.removeState('map/selectedRecorder');
          } else {
            selectedRecorder = this.$store.getters['map/selectedRecorder'];
          }
          if (selectedRecorder && this.$refs.markers) {
            const selectedRecorderIndex = this.permittedRecorders.findIndex((recorder) => {
              return recorder.id === selectedRecorder.id;
            });
            const recorderNotSelected = selectedRecorderIndex === -1;
            if (recorderNotSelected) {
              return;
            }
            if (!this.$refs.markers[selectedRecorderIndex].$markerObject) {
              return;
            }
            this.$refs.markers[selectedRecorderIndex].$markerObject.setIcon(this.selectedIcon);
          }
        }, 30 * 1000);

        this.$store.dispatch('closeRightDrawerInMap');
        await this.fetchPermittedRecorders().catch((err) => {
          console.error(err);
          this.$store.dispatch("setLoadingState", false);
        });

        await this.$store.dispatch('map/fetchLoginPosition');
        const loginPositionInStore = this.$store.getters['map/loginPosition'];

        this.initialPosition.zoomLevel = loginPositionInStore.zoom_level;

        let selectedRecorder;

        if (this.isIE()) {
          selectedRecorder = persistence.restoreState('map/selectedRecorder');
          persistence.removeState('map/selectedRecorder');
        } else {
          selectedRecorder = this.$store.getters['map/selectedRecorder'];
        }

        if (this.$refs.map && this.$refs.markers && selectedRecorder.lat && selectedRecorder.lng) {
          this.moveMapToSelectedRecorderPosition(selectedRecorder);
          this.showDrawers(selectedRecorder);
        } else {
          this.initialPosition.lat = loginPositionInStore.map_latitude;
          this.initialPosition.lng = loginPositionInStore.map_longitude;
        }

        this.loadCompleted = true;

        this.subscribeStateChanges();
        this.$store.dispatch("setLoadingState", false);
      },
      destructor() {
        return new Promise(async (resolve) => {
          // TODO: ポーリング停止(他画面にいるときにMAP画面のエラーが出ないようにする)
          this.handleBeforeDestroy();
          this.infoWinOpen = false;

          // 初期表示位置に戻す
          this.$refs.map.panTo({lat: this.initialPosition.lat, lng: this.initialPosition.lng});
          this.$refs.map.$mapObject.setZoom(this.initialPosition.zoomLevel);
          return resolve();
        });
      },
      initializeForMobileDevices() {
        document.querySelector('body').style.overflowY = 'hidden';
        document.body.scrollTop = 0; // モバイルで地図以外の画面から地図に遷移した際に前のスクロール位置を保持してしまっていたため明示的に0を代入
      },
      /**
       * 描画範囲内のマーカーのみ描画する(負荷対策)
       *
       * 実際のDOMを確認したところ、setVisible(false)はdisplay:noneにして非表示にしているわけではなく、
       * 描画範囲外のDOM自体を削除しているようだった。
       * つまり、例えば1000個のマーカーがあるとしても描画範囲に10個しか表示されていないのならば、
       * DOMはその10個分しか存在しない=10個分のメモリしか消費しない、ということになるはず。
       */
      drawMarkerWithinDrawingRange() {
        if (!this.$refs.markers || !this.$refs.map) {
          return;
        }
        this.$refs.markers.forEach((marker) => {
          const markerObj = marker.$markerObject;
          const markerWithinDrawingRange = this.$refs.map.$mapObject.getBounds().contains(markerObj.getPosition());
          if (markerWithinDrawingRange) {
            markerObj.setVisible(true);
          } else {
            markerObj.setVisible(false);
          }
        });
      },
      async fetchPermittedRecorders() {
        await this.$store.dispatch('map/fetchPermittedRecorders').catch(() => {
          throw new Error();
        });

        if (this.$store.getters['map/permittedRecorders']) {
          this.permittedRecorders = this.$store.getters['map/permittedRecorders'];
        }

        if (this.permittedRecorders) {
          this.permittedRecorders.forEach((rec) => {
            rec.position = {
              lat: Number(rec.latitude),
              lng: Number(rec.longitude),
            };
            // TODO: エラーのあったレコーダーは!つきの画像を指定する
            rec.icon = this.notSelectedIcon;
          });
        }

        // パフォーマンスのために並列で呼び出す
        await Promise.all([
          this.$store.dispatch('recorders/listOrganizations'),
          this.$store.dispatch('recorders/list'),
          this.$store.dispatch('recorders/listRecordersStatus'),
        ]);
      },
      subscribeStateChanges() {
        this.unsubscribe = this.$store.subscribe(async (mutation) => {
          if (mutation.type === 'map/selectRecorder') {
            const selectedRecorder = this.$store.getters['map/selectedRecorder'];

            if (!selectedRecorder.lat || !selectedRecorder.lng) {
              return;
            }

            // レコーダーの読み込みが終わっていない状態ではselectedRecorderのlatとlngは初期値の0になっている
            const initializeNotCompleted = selectedRecorder.lat === 0 || selectedRecorder.lng === 0;
            if (initializeNotCompleted) {
              return;
            }

            if (this.$refs.map && this.$refs.markers) {
              this.moveMapToSelectedRecorderPosition(selectedRecorder);
            }
          }

          if (mutation.type !== 'clickToaLogo') {
            return;
          }

          if (this.$store.getters.path !== '/maps') {
            return;
          }

          const loggedInPosition = this.$store.getters['map/loginPosition'];
          this.closeDrawers();

          if (!this.$refs.map.$mapObject) {
            return;
          }

          this.$refs.map.$mapObject.setCenter({ lat: loggedInPosition.map_latitude, lng: loggedInPosition.map_longitude });
          this.$refs.map.$mapObject.setZoom(loggedInPosition.zoom_level);

          const selectedRecorder = this.$store.getters['map/selectedRecorder'];
          const selectedRecorderIndex = this.permittedRecorders.findIndex((recorder) => {
            return recorder.id === selectedRecorder.id;
          });

          if (selectedRecorderIndex === -1) {
            return;
          }

          if (!this.$refs.markers[selectedRecorderIndex].$markerObject) {
            return;
          }

          this.$refs.markers[selectedRecorderIndex].$markerObject.setIcon(this.notSelectedIcon);
          this.$store.commit('map/selectRecorder', {});
          await this.$store.dispatch('map/clearRecorderIdAndLockStateMap');
          await this.$store.dispatch('recorders/clear');
          this.infoWinOpen = false;
        });
      },
      moveMapToSelectedRecorderPosition(selectedRecorder) {
        this.openInfoWindow(selectedRecorder);
        this.$refs.map.panTo({lat: selectedRecorder.lat, lng: selectedRecorder.lng});

        if (this.beforeIndex !== null) {
          this.$refs.markers[this.beforeIndex].$markerObject.setIcon(this.notSelectedIcon);
          this.$refs.markers[this.beforeIndex].$markerObject.setZIndex(1);
        }

        const selectedRecorderIndex = this.permittedRecorders.findIndex((recorder) => {
          return recorder.id === selectedRecorder.id;
        });
        this.$refs.markers[selectedRecorderIndex].$markerObject.setIcon(this.selectedIcon);
        /**
         * アイコンが重なった場合に下にあるアイコンを選択できなくなってしまうので、
         * 選択したアイコンのzindexを高くすることで、アイコンを前面に出している。
         */
        this.$refs.markers[selectedRecorderIndex].$markerObject.setZIndex(10000);
        this.beforeIndex = selectedRecorderIndex;
      },
      openLeftDrawer() {
        this.$store.dispatch('showLeftDrawerInMap');
      },
      closeDrawers() {
        this.$store.dispatch('closeLeftDrawerInMap');
        this.$store.dispatch('closeRightDrawerInMap');
      },
      onCenterChanged(center) {
        this.mapState.lat = center.lat();
        this.mapState.lng = center.lng();
      },
      onZoomChanged(newZoomLevel) {
        this.mapState.zoomLevel = newZoomLevel;
      },
      openInfoWindow(recorder) {
        this.infoWindowPos = {lat: recorder.lat, lng: recorder.lng};
        this.infoContent = recorder.name;
        this.infoWinOpen = true;
      },
      infoWinClose() {
        this.infoWinOpen = false;
      },
      showDrawers(recorder){
        const selectedRecorder = this.recorderAndIdMapping[recorder.id];
        this.setCurrentRecorder(selectedRecorder);
        this.$store.dispatch('showRightDrawerInMap');
        this.$store.dispatch('showLeftDrawerInMap');
      },
      showCoordinatesChangeModal(event, marker) {
        this.dragging = false;
        this.selectedMarker = marker;
        this.recorderIconDragEvent = event;
        this.selectedMarker.position.lat = event.latLng.lat();
        this.selectedMarker.position.lng = event.latLng.lng();

        // 緯度表示限界暫定値を超えている場合は変更させない
        if (event.latLng.lat() >= this.latitudeLimit || event.latLng.lat() <= -1*this.latitudeLimit) {
          this.$store.dispatch("setLoadingState", true);
          this.infoWinOpen = false;
          this.selectedMarker.position = {
            lat: this.dragStartLat,
            lng: this.dragStartLng,
          };
          this.$store.dispatch("setLoadingState", false);
          this.changeTownRecorderCoordDialog = false;
          this.snackbarOverlatLng = true;
        } else {
          this.changeTownRecorderCoordDialog = true;
        }
      },
      async setNewTownRecorderCoordinates() {
        this.$store.dispatch("setLoadingState", true);
        this.infoWinOpen = false;

        const payloadRecorder = { ...this.selectedMarker }; // オブジェクトスプレッド演算子を使用して中身が同じオブジェクトを作成
        payloadRecorder.latitude = String(this.recorderIconDragEvent.latLng.lat());
        payloadRecorder.longitude = String(this.recorderIconDragEvent.latLng.lng());
        delete payloadRecorder.position;
        await this.$store.dispatch('recorders/updateCoordinates', { id: payloadRecorder.id, payload: payloadRecorder }).catch((err) => {
          console.error(err);
          this.$store.dispatch("setLoadingState", false);
        });

        await this.fetchPermittedRecorders().catch((err) => {
          console.error(err);
          this.$store.dispatch("setLoadingState", false);
        });

        const selectedRecorder = this.recorderAndIdMapping[payloadRecorder.id];
        if (selectedRecorder) {
          this.setCurrentRecorder(selectedRecorder);
        }

        this.$store.dispatch("setLoadingState", false);
        this.changeTownRecorderCoordDialog = false;
        this.snackbar = true;
      },
      cancelTownRecorderCoordinateChange() {
        this.infoWinOpen = false;
        this.selectedMarker.position = {
          lat: this.dragStartLat,
          lng: this.dragStartLng,
        };
        this.changeTownRecorderCoordDialog = false;
      },
      startTownRecorderIconDrag(event) {
        this.dragging = true;
        this.infoWinOpen = false;
        this.dragStartLat = event.latLng.lat();
        this.dragStartLng = event.latLng.lng();
      },
      setCurrentRecorder(recorder) {
        this.$store.commit('recorders/setRecorder', recorder);
        this.$store.commit('map/selectRecorder', recorder);
      },
      isDraggable(recorder) {
        const map = this.$store.getters['map/recorderIdAndLockStateMap'];
        if (recorder.id in map) {
          return !map[recorder.id];
        }
      },
    },
  };
</script>
<style lang="scss" scoped>
  $header-height: 60px;

  .right-btn {
    right: 0;
    top: 50px;
    position: absolute;
  }

  .map-left-drawer {
    height: calc(100vh - #{$header-height})!important;
    position: fixed;
    top: 60px;
  }

  .map-left-drawer-mobile {
    height: calc(100% - #{$header-height})!important;
    position: fixed;
    top: 60px;
  }

  .map-right-drawer {
    height: calc(100vh - #{$header-height})!important;
    top: 60px;
  }

  .map-right-drawer-mobile {
    height: calc(100% - #{$header-height})!important;
    top: 60px;
  }

  .scroll-disabled {
    overflow: hidden;
  }

  .map /deep/ {
    // IE11
    @media screen and (-ms-high-contrast: active), screen and (-ms-high-contrast: none) {
      .gm-style-iw-d {
        overflow:hidden!important; // IE11でのみInfoWindowにスクロールバーがついてしまいダサかったため消す
      }
    }
    // Info Window
    .gm-style-iw {
      padding-top: 20px;
      width: 200px;
      min-height: 70px;
      // Close Button
      button {
        right: 0!important;
        img {
          height: 20px!important;
          width: 20px!important;
        }
      }
    }
  }
</style>
