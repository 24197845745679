<template>
  <div class="treeview">
    <v-treeview
      :active.sync="recorderSelected"
      :items="recorders"
      :activatable="activatable"
      :open.sync="currentOrganization"
      hoverable
      item-key="id"
      open-on-click
    >
      <template
        slot="prepend"
        slot-scope="{ item, open, leaf, active }"
      >
        <div @click="onClickItem(active,$event)">
          <v-icon
            v-if="existsInvalidStatus(getDeviceStatusesFromRecorders(item.children))"
            color="amber"
          >
            warning
          </v-icon>
          <v-icon v-else-if="item.children">
            domain
          </v-icon>
          <v-icon
            v-else-if="active"
            color="primary"
          >
            chevron_right
          </v-icon>
          <div
            v-else
            class="empty-icon"
          />
        </div>
      </template>
      <template
        slot="label"
        slot-scope="{ item, active }"
      >
        <div @click="onClickItem(active,$event)">
          <v-tooltip :disabled="$vuetify.breakpoint.xs" bottom>
            <template v-slot:activator="{ on }">
              <span
                v-on="on"
                @mouseover="setDeviceErrors(item)"
              >
                {{ item.name }}
              </span>
            </template>
            <span>{{ item.name }}</span>
            <template v-if="device_errors">
              <div>{{ device_errors }}</div>
            </template>
          </v-tooltip>
        </div>
      </template>
      <template
        slot="append"
        slot-scope="{ item, active }"
      >
        <div @click="onClickItem(active,$event)">
          <template v-if="!item.children">
            <!-- device_statusによってアイコンを出し分ける -->
            <v-icon
              slot="activator"
              v-if="item.device_status.rec_sd_status === '2'"
              color="amber"
            >
              warning
            </v-icon>
            <v-icon
              slot="activator"
              v-else-if="item.device_status.rec_ssd_status === '0'"
              color="amber"
            >
              warning
            </v-icon>
            <v-icon
              slot="activator"
              v-else-if="item.device_status.sd_status === '0'"
              color="amber"
            >
              warning
            </v-icon>
            <v-icon
              slot="activator"
              v-else-if="item.device_status.ssd_status === '0'"
              color="amber"
            >
              warning
            </v-icon>
            <v-icon
              slot="activator"
              v-else-if="item.device_status.temp_status === '0' || item.device_status.temp_status === '1'"
              color="amber"
            >
              warning
            </v-icon>
            <v-icon
              slot="activator"
              v-else-if="item.device_status.wlan_status === '0'"
              color="amber"
            >
              warning
            </v-icon>
            <v-icon
              slot="activator"
              v-else-if="item.device_status.cam_status === '0' || item.device_status.cam_status === '2'"
              color="amber"
            >
              warning
            </v-icon>
            <v-icon
              slot="activator"
              v-else-if="item.device_status.cam_mcu_status === '0'"
              color="amber"
            >
              warning
            </v-icon>
            <v-icon
              slot="activator"
              v-else-if="item.device_status.sub_mcu_status === '0'"
              color="amber"
            >
              warning
            </v-icon>
            <v-icon
              slot="activator"
              v-else-if="item.device_status.auxin_1 === 'on'"
              color="amber"
            >
              warning
            </v-icon>
            <v-icon
              slot="activator"
              v-else-if="item.device_status.auxin_2 === 'on'"
              color="amber"
            >
              warning
            </v-icon>
            <v-icon
              slot="activator"
              v-else-if="item.device_status.auxin_3 === 'on'"
              color="amber"
            >
              warning
            </v-icon>
            <v-icon
              slot="activator"
              v-else-if="item.device_status.operation_status === '0'"
              color="amber"
            >
              warning
            </v-icon>
          </template>
        </div>
      </template>
    </v-treeview>
  </div>
</template>

<script>
  import leftDrawerMixin from '@/components/mixins/leftDrawer';

  export default {
    components: {},
    mixins: [ leftDrawerMixin ],
    data() {
      return {
        activatable: true,
        isActive: false,
        recorderSelected: [],
        currentOrganization: [],
        unsubscribe: ()=>{},
      };
    },
    computed: {
      recorders() {
        const organizations = this.$store.state.recorders.organizations;

        return _.chain(this.$store.getters['map/permittedRecorders'])
          .groupBy(i => organizations[i.organization_id])
          .map((v, k) => {
            var orgName = '組織なし';
            if (k !== 'undefined') {
              orgName = k;
            }

            const children = _.map(v, i => {
              return {
                aux: i.aux,
                device_id: i.device_id,
                device_status: i.device_status,
                id: i.id,
                lat: Number(i.latitude),
                lng: Number(i.longitude),
                name: i.name,
                organization_id: i.organization_id,
                phone_no: i.phone_no,
                plan: i.plan,
                sim_traffic_limit: i.sim_traffic_limit,
                type: i.type,
                vpn: i.vpn,
                youtube: i.youtube,
              };
            });

            return {
              name: orgName,
              id: v[0].organization_id,
              children: _.orderBy(children, ['name'], ['asc']),
            };
          })
          .orderBy(['name'], ['asc'])
          .value();
      },
      recorderAndIdMapping() {
        const recorders = {};
        this.recorders.forEach((organizations) => {
          const children = organizations.children;
          children.forEach((child) => {
            recorders[child.id] = (child);
          });
        });
        return recorders;
      },
    },
    watch: {
      /**
       * 左ドロワーでレコーダーが選択された際に走る処理。主に選択されたレコーダーの最新状態をAPIから取得するのが役割。
       *
       * @param {string[]} selectedRecorderIdList 選択されたレコーダーのID。配列だが1要素しか入ってこない。
       * @param {string[]} forCompareSelectedRecorderIdList レコーダーIDの新旧比較用に用意。
       */
      async recorderSelected(selectedRecorderIdList, forCompareSelectedRecorderIdList) {
        // watchで監視しているrecorderSelectedの変更前後にデータ上差分がなければreturnする
        // これによりrecorders/getとrecorders/getExtdeviceWaterGateOpeningDegreesが2回ずつ実行されることを防ぐ
        if (selectedRecorderIdList[0] === forCompareSelectedRecorderIdList[0]) {
          return
        }
        const [selectedRecorderId] = selectedRecorderIdList // 選択されたレコーダーID

        this.activatable = false;
        this.sleep(1000).then(() => this.activatable = true); // 後続処理をブロッキングしないようにawaitで待たないようにしている。
        if (selectedRecorderId === undefined) { // 何らかの理由でundefinedなら処理しない
          return;
        }
        this.$store.dispatch('showRightDrawerInMap'); // 右ドロワ表示状態をセット
        this.$store.dispatch('recorders/get', selectedRecorderId); // 選択したレコーダーIDのレコーダー情報を取得

        const selectedRecorder = this.recorderAndIdMapping[selectedRecorderId]; // recorderIDとrecorderのMAPから、recorderオブジェクト取得
        if (!selectedRecorder) {
          return;
        }

        this.$store.dispatch('recorders/clearRecorder'); // 何故クリアしているのか？
        this.$store.commit('map/selectRecorder', selectedRecorder);
        this.$store.commit('recorders/setRecorder', selectedRecorder);
        this.$store.dispatch('recorders/getExtdeviceWaterGateOpeningDegrees', selectedRecorder.id);
      }
    },
    created() {
      if (this.$store.getters['auth/getLoginUserId']){
        this.$store.dispatch('patterns/list',this.$store.getters['auth/getLoginUserId']);
      }
    },
    mounted(){
      // Storeを監視して、表示状態が切り替わったタイミングでStore上のselectedRecorderを取得
      // Mapからピンを選択された場合に初期値を入れるための処理
      this.unsubscribe = this.$store.subscribe(
        (mutation) => {
          if(mutation.type === 'showLeftDrawerInMap'){
            const selectedRecorder = this.$store.getters['map/selectedRecorder'];
            // ↓storeが保持してるselectedRecorderが一致していない場合のみ、this.recorderSelectedの処理を行う。
            if(this.recorderSelected[0] !== selectedRecorder.id) {
              this.recorderSelected = [selectedRecorder.id];
            }
            // 組織が開いてなかった場合に開く
            if(this.currentOrganization.indexOf(selectedRecorder.organization_id) === -1) {
              this.currentOrganization.push(selectedRecorder.organization_id);
            }
          }
        }
      )
    },
    beforeDestroy() {
      this.unsubscribe();
    },
    methods: {
      onClickItem(isActive, event) {
        if(isActive){
          // イベントの伝播を停止することで非アクティブを抑制
          event.stopPropagation();
          // 空pushすることでwatchを強制的に発火させている。要素数は増えないのでpopは必要なし
          this.recorderSelected.push();
          // 素直に右ドロワー&吹き出しを表示する形式
          // 更新を発生させたくない場合はこちらを使用
          // this.$store.dispatch('showRightDrawerInMap');
          // this.$store.dispatch('showInfoWindowInMap'); このActionは未定義のため、使用する場合は自作してください
        }
      }
    },
  };
</script>
<style lang="scss" scoped>
  @import "../../assets/style/treeview";
</style>
